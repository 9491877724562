import {dateFormatter, timeFormatter} from '@hconnect/uikit'
import {TFunction} from 'i18next'
import moment, {Moment} from 'moment'

import {BusinessLineType} from '../../../../common/types'
import {EvoZeroBadge} from '../../../../Molecules/EvoZeroBadge'
import {FeaturesState} from '../../../../Organisms/Features'
import {Plant} from '../../../../Organisms/Plants/Plants.types'
import {PermissionContext, PermissionTypes} from '../../../../Permissions'
import {Delivery} from '../../../Finance/Invoices/Delivery.types'
import {DeliveryTogglesType} from '../../components'
import {QuantityUomToSymbolComponent} from '../../Order.components'
import {Order, ShippingAddress} from '../../Order.types'
import {getIsAGG, getIsCEM, getIsRMC} from '../../Order.utils'
import {Box} from '@material-ui/core'

export const formattedDate: (
  date: string | Date | Moment | undefined,
  language: string,
  eventLocationOffset?: number
) => [string, string] = (date, language, eventLocationOffset?) => {
  if (!date) return ['', '']
  const dateOnly = dateFormatter(date, language, eventLocationOffset)
  const timeOnly = timeFormatter(date, language, eventLocationOffset)
  return [dateOnly, timeOnly]
}

export const formattedFlowRateSpacing = (order: Order | undefined, t: TFunction) => {
  if (!order?.schedules) return '-'

  const {arrivalSpacing, quantityPerHour} = order?.schedules[0] || {}
  const {quantityUom} = order?.lineItems[0] || {}

  if (!quantityPerHour)
    return `${moment.duration(arrivalSpacing).asMinutes()} ${t('order.orderDetails.minutes')}`

  return (
    <>
      {quantityPerHour} <QuantityUomToSymbolComponent uom={quantityUom} />{' '}
      {t('order.orderDetails.quantityPerHour')}
    </>
  )
}

export const getTruckName = (getFeature: (name: string) => boolean, deliveries?: Delivery[]) => {
  if (!deliveries?.length) return undefined
  const useTruckLicensePlate = getFeature('TruckLicensePlate')

  return useTruckLicensePlate ? deliveries[0].truckLicensePlate : deliveries[0].truckName
}

export const getModeOfTransport = (t: TFunction, deliveries?: Delivery[]) =>
  deliveries && deliveries.length && deliveries[0].modeOfTransport
    ? t(`invoice.expand.modeoftransport.${deliveries[0].modeOfTransport}`)
    : undefined

export const getTrailer = (deliveries?: Delivery[]) =>
  deliveries && deliveries.length && deliveries[0].transportVehicleDetails?.trailer
    ? deliveries[0].transportVehicleDetails.trailer
    : undefined

export const getDriverName = (deliveries?: Delivery[]) =>
  deliveries && deliveries.length && deliveries[0].transportVehicleDetails?.driverName
    ? deliveries[0].transportVehicleDetails.driverName
    : undefined

export const getLoadingNumber = (order?: Order, deliveries?: Delivery[]) => {
  if (!order) return undefined

  const {businessLine} = order
  if (businessLine === BusinessLineType.AGG) {
    return order?.loadingNumber
  } else if (businessLine === BusinessLineType.CEM && deliveries?.length) {
    return deliveries[0].loadingNumber
  }

  return undefined
}

export const getMaterialAndVolume = (order?: Order) => {
  if (!order) return undefined

  const {materialDescription, quantity, quantityUom} = order?.lineItems[0] || {}

  return (
    <>
      {quantity} <QuantityUomToSymbolComponent uom={quantityUom} />
      {' - '}
      <Box display="flex" flexDirection="columns" style={{gap: 2}}>
        {materialDescription} <EvoZeroBadge isCarbonSavingProduct={order.isCarbonSavingProduct} />
      </Box>
    </>
  )
}

export const getAddress = (
  getFeature: (name: string) => boolean,
  address?: ShippingAddress | Plant
) => {
  if (!address) return undefined

  const showState = getFeature('ShowState')

  return `${address.street}, ${address.city}, ${showState ? address.state : ''} ${
    address.postalCode
  }`
}

export const getDeliveryInstructions = (order?: Order) => {
  if (!order || order.purchaseOrderType !== 'YHUB') return undefined

  return order.note?.itemTexts
    .slice(1, 4)
    .filter((value) => value !== null && value !== undefined)
    .join('')
}

export const getDeliveryToggles = (
  features: FeaturesState,
  permissions: PermissionContext,
  order: Order
): DeliveryTogglesType => {
  const {getFeature, getResolutionByNameAndConstraint, normalized} = features
  const {hasPermission} = permissions

  const isDeliveryLoadFinished = getFeature('DeliveryLoadFinished')
  const epodEnabledForOrderExpand = getFeature('ePOD')
  const isCustomerDeliveryNumber = getFeature('CustomerDeliveryNumber')
  const isSloCountry = getFeature('SLO2Countries')
  const isFilteringOutCancelledDeliveries =
    getFeature('ExcludeAllCancelledDeliveries') || getFeature('ExcludeNotLoadedCancelledDeliveries')
  const aggColumnsForRmc = getFeature('AggColumnsForRmc')
  const useTruckLicensePlate = getFeature('TruckLicensePlate')
  const isCEM = getIsCEM(order)
  const isRMC = getIsRMC(order)
  const isAGG = getIsAGG(order)
  const hasInvoicePermission = hasPermission(PermissionTypes.VIEW_INVOICES)
  const isInvoiceDetailsDisabled = (invoiceId: string) =>
    getResolutionByNameAndConstraint('InvoiceDetails', normalized, 'connectorId').disabled.includes(
      invoiceId.split('.')[0]
    ) || !getFeature('Finance')

  return {
    isCustomerDeliveryNumber,
    useTruckLicensePlate,
    isAGG,
    isCEM,
    isRMC,
    epodEnabledForOrderExpand,
    isSloCountry,
    aggColumnsForRmc,
    isDeliveryLoadFinished,
    isFilteringOutCancelledDeliveries,
    hasInvoicePermission,
    isInvoiceDetailsDisabled
  }
}

export const filterDeliveriesIfService = (deliveries?: Delivery[]) => {
  const {serviceDeliveries, materialDeliveries} = (deliveries || []).reduce(
    (acc, delivery) => {
      const relatedDeliveryId = delivery.links
        ?.find((link) => link.rel === 'related-delivery')
        ?.href?.split('/')
        ?.pop()

      if (relatedDeliveryId) {
        delivery.relatedDelivery = deliveries?.find((d) => d.deliveryId === relatedDeliveryId)
      }

      if (delivery.isService) {
        acc.serviceDeliveries.push(delivery)
      } else {
        acc.materialDeliveries.push(delivery)
      }
      return acc
    },
    {serviceDeliveries: [], materialDeliveries: []} as {
      serviceDeliveries: Delivery[]
      materialDeliveries: Delivery[]
    }
  )
  return {serviceDeliveries, materialDeliveries}
}
